import axios from "axios";
import { api_Url } from "@/services/constants";

const apiUrl = api_Url;

// const getToken = async () => {
//     // var d1 = localStorage.getItem(server.ACCEESS_EXPIRED) ?? 0;
//     // if (d1 == null)
//     //     d1 = 0;
//     // var d2 = getExpireTime(0);
//     // if (d2 > d1)
//     //     await refreshToken();
//     // return localStorage.getItem(server.ACCEESS_TOKEN) ?? "";
//     return '';
// };

const getInstanceWithOutToken = async () => {
    const instance = axios.create({
        baseURL: `${apiUrl}`,
        headers: {'Content-Type': `application/json`},
        timeout: 10000
    });
    return instance;
};

// const getInstance = async () => {
//     const key = await getToken();
//     const token = Buffer.from(key)
//     const instance = axios.create({
//         baseURL: `${apiUrl}`,
//         headers: {'Authorization': `Bearer ${token}`, 'Content-Type': `application/json`},
//         timeout: 10000
//     });
//     return instance;
// };

const login = async (phoneNumber, password) => {
    const instance = await getInstanceWithOutToken();
    let payload = {
        "username": phoneNumber,
        "password": password
    };
    return (
        await instance.post("/member/login", payload)
        // .then(response => {
        //     // console.log(response.data);
        //     var result = response.data.res_code=="000";
        //     if (result)
        //         localStorage.setItem(server.TOKEN_KEY, response.data.phone_id);
        //     else {
        //         localStorage.setItem(server.TOKEN_KEY, "");
        //         if (response.data.res_code=="001")
        //             throw response.data.res_desc;
        //     }
        // })
    ).data;
};

const register = async (phoneNumber, password, confirmPassword, firstname, lastname, email) => {
    const instance = await getInstanceWithOutToken();
    let payload = {
        "username": phoneNumber,
        "password": password,
        "confirmPassword": confirmPassword,
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
    };
    return (
        await instance.post("/member/register", payload)
    ).data;
};

const confirmRegister = async (phoneNumber, otpCode) => {
    const instance = await getInstanceWithOutToken();
    let payload = {
        "username": phoneNumber,
        "otpCode": otpCode
    };
    return (
        await instance.post("/member/confirmregister", payload)
    ).data;
};

const resendOtp = async (phoneNumber) => {
    const instance = await getInstanceWithOutToken();
    let payload = {
        "username": phoneNumber
    };
    return (
        await instance.post("/member/resendotp", payload)
    ).data;
};

const resetPassword = async (phoneNumber) => {
    const instance = await getInstanceWithOutToken();
    let payload = {
        "username": phoneNumber
    };
    return (
        await instance.post("/member/resetpassword", payload)
    ).data;
};

const confirmResetPassword = async (phoneNumber, password, confirmPassword, otpCode) => {
    const instance = await getInstanceWithOutToken();
    let payload = {
        "username": phoneNumber,
        "password": password,
        "confirmPassword": confirmPassword,
        "otpCode": otpCode
    };
    return (
        await instance.post("/member/confirmresetpassword", payload)
    ).data;
};

export default {
    login,
    register,
    confirmRegister,
    resendOtp,
    resetPassword,
    confirmResetPassword
};