<template>
  <div id="app">
    <!-- <div class="login_bg" :style="login_bg"></div> -->
    <div class="login_bg"></div>

    <v-layout justify-space-between v-if="!isBackGroundOnly">
      <div class="lang_cursor" style="padding: 10px" v-if="this.$i18n.locale=='en'">
        <v-img 
          contain
          :src="require('@/assets/th.png')"
          height="30"
          width="30"
          @click="langThaiClick"
        />
      </div>
      <div class="lang_cursor" style="padding: 10px" v-else>
        <v-img 
          contain
          :src="require('@/assets/en.png')"
          height="30"
          width="30"
          @click="langEngClick"
        />
      </div>
      <div style="padding: 10px; height: 30px;">
        <v-btn rounded color="#3949AB darken-4" width="120" dark @click="registerClick" v-if="isShowRegister">{{ $t('login.register') }}</v-btn>
        <!-- <v-btn rounded color="#3949AB darken-4" width="120" dark disabled depressed v-else></v-btn> -->
      </div>
    </v-layout>

    <!-- <br />
    <br /> -->
    <v-layout justify-center>
      <v-img 
        contain
        :src="require('@/assets/logo2.png')"
        max-height="350"
        max-width="350"
      />
    </v-layout>

    <!-- <br />
    <br />
    <br />
    <br /> -->
    <v-layout justify-center v-if="!isBackGroundOnly">
      <v-btn style="cursor: default" rounded color="#1976D2" width="200" dark depressed>{{ pageTitle }}</v-btn>
    </v-layout>
  </div>
</template>

<script>

export default {
  name: "LoginBackGround",
  // data() {
  //   return {
  //     // login_bg: { backgroundImage: `url(${require('@/assets/login_bg.jpg')})` }
  //   };
  // },
  methods: {
    langThaiClick() {
      this.$i18n.locale = 'th';
      localStorage.setItem('lang', 'th');
    },
    langEngClick() {
      this.$i18n.locale = 'en';
      localStorage.setItem('lang', 'en');
    },
    registerClick() {
        this.$router.push(`/register`);
    }
  },
  props: {
    pageTitle: String,
    isShowRegister: Boolean,
    isBackGroundOnly: Boolean
  },
};

</script>

<style scoped>
.login_bg {
  background-image: url("../assets/login_bg.jpg");
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  background-size:100% 100%;
  background-repeat: no-repeat;
  position: fixed;
  /* left:0;right:0;top:0;bottom:0; */
}
/* .over_bg {
    position: relative;
} */
.lang_cursor {
  cursor: pointer;
}
</style>